
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { PartialChannel } from '@/interfaces/Channel';
import { formatLocalTime } from '@/utils/format-time';

import {
  getChannel
} from '@/services/api';

export default defineComponent({
  setup() {
    const channel = ref<PartialChannel>({});
    const channelId = useRoute().params.id as string;

    const page = ref(1);

    const fetchChannels = async() => {
      const { data } = await getChannel({ channelId });
      channel.value = data;
    };

    onMounted(() => {
      fetchChannels();
    });

    return {
      channel,
      page,
      formatLocalTime
    };
  }
});
